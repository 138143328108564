@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Square+Peg&display=swap");

body {
  font-family: "Karla", sans-serif;
  background-color: white;
}

.font-caligraphy {
  font-family: "Square Peg", cursive;
}

@layer components {
  .menu-active {
    @apply bg-theme-black-color text-white rounded-sm justify-start;
  }

  .menu-icon {
    @apply w-6 min-h-[24px] min-w-[24px] h-6;
  }

  .hexagon::before {
    @apply absolute content-["\2B22"] top-0 left-0;
  }
}

@layer utilities {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.notificationWrapper {
  @apply flex items-center justify-between w-96 bg-gray-900 px-4 py-6 text-white shadow-2xl hover:shadow-none transform-gpu translate-y-0 hover:translate-y-1 rounded-xl relative transition-all duration-500 ease-in-out;
}

.mentions-input-container__suggestions {
  @apply py-1 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.mentions-input-container__suggestions__item {
  @apply hover:bg-gray-100 px-2 py-1 text-sm;
}

.mentions-input-container textarea {
  @apply block w-full border-gray-300 rounded-md shadow-sm resize-none focus:ring-theme-red focus:border-theme-red sm:text-sm;
}

.iconWrapper {
  @apply text-xl;
}

.contentWrapper {
  @apply flex flex-col items-start justify-center ml-4 cursor-default;
}

.contentWrapper h1 {
  @apply text-base text-gray-200 font-semibold leading-none tracking-wider;
}

.contentWrapper p {
  @apply text-sm text-gray-400 mt-2 leading-relaxed tracking-wider;
}

.closeIcon {
  @apply absolute top-2 right-2 cursor-pointer text-lg;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.react-pdf__Page__textContent span {
  transform: translateY(0%) !important;
}

.vimeo_video_player_iframe_container {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.discount_badge {
  display: flex;
  -webkit-box-align: center;
  margin-left: 10px;
  align-items: center;
  position: relative;
  background-color: #fcdddf;
  font-size: 12px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  color: rgb(255, 58, 92);
  border-top: 0.1rem solid #ea4c5f;
  border-bottom: 0.1rem solid #ea4c5f;
  border-right: 0.1rem solid #ea4c5f;
  border-image: initial;
  width: fit-content;
  border-left: none;
  height: 18px;
  padding: 2px;
  padding-right: 4px;
}

.discount_badge::before {
  content: "";
  position: absolute;
  right: 100%;
  top: -0.1rem;
  border-width: 9px;
  border-style: solid;
  border-color: transparent #ea4c5f transparent transparent;
  border-image: initial;
}

.discount_badge::after {
  content: "";
  position: absolute;
  right: 100%;
  top: 0px;
  border-width: 7.5px;
  border-style: solid;
  border-color: transparent #fcdddf transparent transparent;
  border-image: initial;
}

*::-webkit-scrollbar {
  width: 12px;
  /* width of the scrollbar track */
  height: 15px;
  /* height of the scrollbar track */
}

*::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* color of the scrollbar track */
  border-radius: 6px;
  /* border radius of the scrollbar track */
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* color of the scrollbar thumb */
  border-radius: 6px;
  /* border radius of the scrollbar thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
  /* color of the scrollbar thumb on hover */
}

#zmmtg-root {
  display: none;
}

.hide-scrollbars {
  &::-webkit-scrollbar {
    display: none;
  }
}

.slick-list {
  & .slick-slide>div {
    padding: 0 10px;
  }
}

.dashboard-banner-gradience {
  background: none;

  @media (max-width: 767px) {
    background: linear-gradient(96.04deg, #FF3632 10.73%, #FF8D4E 93.75%),

  }
}
